import '../scss/styles.scss';
import moment from 'moment'

(function ($) {
	const body = $('body');
	const hightContrastButtton = $('#dark-theme-button');
	const changeFontButtons = $('button.aria-font');

	if ($('html').attr('lang') === 'pl-PL') {
		moment.lang('pl');
	}
	const setTheme = (theme) => {
		body.addClass(theme);
		localStorage.setItem('theme', theme)
	}

	const removeTheme = () => {
		body.removeClass('dark-theme');
		localStorage.removeItem('theme')
	}

	hightContrastButtton.on('click', (item) => {
		let theme = `${item.currentTarget.attributes.id.value.split('-')[0]}-theme`;
		(localStorage.getItem('theme')) ? removeTheme(): setTheme(theme);
	});

	(localStorage.getItem('theme')) ? setTheme(localStorage.getItem('theme')): false;

	changeFontButtons.on('click', (e) => {

		const _html = $('html').removeAttr('style');

		switch (e.currentTarget.id) {
			case 'font-bigger':
				_html.css("fontSize", "75%");

				break;
			case 'font-smaller':
				_html.css("fontSize", "50%");

				break;
			case 'font-normal':
				_html.css("fontSize", "62.5%");

				break;
			default:
		}
	});

	//setting for man news block
	$('#main-news-slider').slick({
		arrows: false,
		prevArrow: "<div class='slick-prev slick-arrow'></div>",
		nextArrow: "<div class='slick-next slick-arrow'></div>",
		dots: true,
		loop: true,
		autoplay: false,
		autoplaySpeed: 5000,
		lazyLoad: 'ondemand'
	}).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		const activeSlide = $(`#main-news-slider #slick-slide0${nextSlide}`);
		const themeClass = activeSlide.data('class');
		$('.main-news__item').removeClass(function (index, className) {
			return (className.match(/(^|\s)slider-article--\S+/g) || []).join(' ');
		}).addClass(themeClass);

		$('#current-image-slider').attr('src', activeSlide.data('image'));
		$('#prev-image-slider').attr('src', activeSlide.data('prev-image'));
	});

	//setting for man slider block
	$('.main-slider .slick-slider').slick({
		arrows: false,
		dots: true,
		loop: true,
		autoplay: true,
		autoplaySpeed: 5000,
		lazyLoad: 'ondemand'
	}).on('beforeChange', function (event, slick, currentSlide, nextSlide) {
		const activeSlide = $(`.main-slider #slick-slide0${nextSlide}`);
		const themeClass = activeSlide.data('class');
		$('.main-slider__item').removeClass(function (index, className) {
			return (className.match(/(^|\s)slider-article--\S+/g) || []).join(' ');
		}).addClass(themeClass);

		$('#current-image-slider').attr('src', activeSlide.data('image'));
		$('#prev-image-slider').attr('src', activeSlide.data('prev-image'));
	});

	$('#um-additional-section').detach().appendTo('.site-logo')

	const menu = $('.slideout-menu > .menu-item > a');

	menu.on('click', () => {

		$.each(menu, function () {

			const _parent = $(this).parent();

			if (_parent.length > 0) {

				_parent.removeClass('sfHover')
					.find('ul.sub-menu')
					.removeClass('toggled-on')
					.find('ul.sub-menu').parent()
					.removeClass('sfHover');
			}
		})
	});

	// calendar

	$(document).ready(function () {

		if ($('body.theme--biznes.home').length > 0) {
			const _URL = '/wp-content/themes/generatepress_child/events.php';
			const _currentMonth = moment().format("YYYY-MM");
			const _the_month = $('#TheMonth');
			let avaiableDates;
			let is_next_month = true;
			let month_array = [];

			const getDates = () => {
				fetch(`${_URL}?getDates=true&lang=` + $('html').attr('lang')).then(res => res.json()).then(data => {
					avaiableDates = data;
					updateNav(_currentMonth)
				});
			}

			const updateNav = (currentMonth) => {
				const index = avaiableDates.indexOf(currentMonth);

				$('#previous').attr({
					'disabled': (avaiableDates[0] === currentMonth),
					'data-month': avaiableDates[index - 1]
				})

				$('#next').attr({
					'disabled': (avaiableDates[avaiableDates.length - 1] === currentMonth),
					'data-month': avaiableDates[index + 1]
				})
			}

			const getEvents = (month) => {

				const _emptyResultsContainer = $('.events-calendar__empty-results-main');
				const _events = $("#events");
				_emptyResultsContainer.hide();
				_events.empty().addClass('calendar-event-loader');
				_the_month.html(`<span>${moment(month).format('MMMM')}</span>  <span>${moment(month).year()}</span>`);

				fetch(`${_URL}?month=${month}` + `&lang=` + $('html').attr('lang'))
					.then(res => {
						if (res.ok) {
							return res.json()
						} else {
							return Promise.reject(`Http error: ${res.status}`);

						}
					})
					.then(data => {

						_events.removeClass('calendar-event-loader');

						if (month != month_array[month_array.length - 1] && is_next_month == false) {
							getEvents(month_array[month_array.length - 1]);
						} else {

							is_next_month = true;
							month_array = [];

							if (data) {
								if (data.length === 0) {
									_emptyResultsContainer.show();
									return false;
								}
							} else {
								_emptyResultsContainer.show();
								return false;
							}

							for (var item of data) {

								let templateSingleEvent = `
								<div id="event" class="events-list__item">
									<a class="events-list__link  events-list__link--${item.domaineName}" href="${item.permalink}">
										<span id="dateEvent" class="events-list__date">${item.date_start}`;

								if (item.date_end !== "") templateSingleEvent += ` - ${item.date_end}`;

								templateSingleEvent += `</span>
										<span id="description" class="events-list__description">${item.title} 
								`;

								if (item.excerpt !== "") templateSingleEvent += ` - ${item.excerpt}`;

								templateSingleEvent += `
										</span>
									</a>
								</div>
								`;
								_events.append(templateSingleEvent);
							}
						}
					})
					.catch(error => {
						console.log(error);
					});
			}

			getDates();
			getEvents(_currentMonth);

			$('.events-calendar__button').on('click', function () {

				const month = $(this).attr('data-month');
				month_array.push(month);
				updateNav(month_array[month_array.length - 1]);
				_the_month.html(
					`<span>
				${moment(month_array[month_array.length - 1]).format('MMMM')}
				</span>  
				<span>
				${moment(month_array[month_array.length - 1]).year()}
				</span>`
				);

				if (!is_next_month) return;
				is_next_month = false;

				setTimeout(() => {
					getEvents(month_array[month_array.length - 1]);
				}, 400);

			});

		}
		//end if is biznes

	});

	// remove empty containers from footer

	jQuery('.inside-footer-widgets > .grid-parent').each(function () {
		let _this = jQuery(this);
		if (_this.text().trim() === '') {
			_this.remove()
		}
	});

	$('.footer-widgets .menu > .menu-item').each(
		function () {
			const _this = $(this);
			const regx = /biznes.*/i.test(_this[0].innerText);

			if (regx) {
				_this.remove();
			}
		}
	);

})(jQuery);


(function ($) {

	var attachClickEventToSearch = function () {
		$('#searchNav #categories_blog_name').on('change', function () {
			var allSelectedCategories = [];
			var categories = {
				"blogName": ""
			};
			$("input[name='rangeStart']").attr('range', '' + 0);
			$("input[name='rangeEnd']").attr('range', '' + 15);
			var rangeStart = $("[name='rangeStart']").attr('range');
			var rangeEnd = $("[name='rangeEnd']").attr('range');
			const categories_blog_name = $(this).val();

			if (categories_blog_name !== '' && categories_blog_name.toLowerCase() !== 'all') {

				categories = {
					"blogName": categories_blog_name
				};
				allSelectedCategories.push(categories);

				var request = JSON.stringify(allSelectedCategories);

				fetch(`/wp-content/themes/generatepress_child/newsposts.php?domains=` + request + `&lang=` + $('html').attr('lang') + `&rangeStart=` + parseInt(rangeStart) + `&rangeEnd=` + parseInt(rangeEnd)).then(res => {
					return res.text();
				}).then(data => {
					$('#myPostsPerCategory').empty();
					$('#myPostsPerCategory').html(data);
				})
			} else {

				fetch(`/wp-content/themes/generatepress_child/newsposts.php?lang=` + $('html').attr('lang') + `&rangeStart=` + parseInt(rangeStart) + `&rangeEnd=` + parseInt(rangeEnd)).then(res => {
					return res.text();
				}).then(data => {
					$('#search-categories').empty();
					$('#search-categories').html(data);
					attachClickEventToSearch();
					attachClickEventToRangeEnd();
					attachClickEventToRangeStart();

				})
			}
		});
	}

	var attachClickEventToRangeEnd = function () {
		$("input[name='rangeEnd']").on('click', function () {
			var allSelectedCategories = [];
			var categories = {
				"blogName": ""
			};
			var blogName = "";
			var rangeStart = $("input[name='rangeStart']").attr('range');
			var rangeEnd = $("input[name='rangeEnd']").attr('range');
			$("input[name='rangeStart']").attr('range', '' + (parseInt(rangeStart) + 15));
			$("input[name='rangeEnd']").attr('range', '' + (parseInt(rangeEnd) + 15));
			rangeStart = $("input[name='rangeStart']").attr('range');
			rangeEnd = $("input[name='rangeEnd']").attr('range');

			const categories_blog_name = $('#searchNav #categories_blog_name').val();

			const back_to_top = $('.generate-back-to-top');

			if (categories_blog_name !== '' && categories_blog_name.toLowerCase() !== 'all') {

				categories = {
					"blogName": categories_blog_name
				};
				allSelectedCategories.push(categories);

				var request = JSON.stringify(allSelectedCategories);

				fetch(`/wp-content/themes/generatepress_child/newsposts.php?domains=` + request + `&lang=` + $('html').attr('lang') + `&rangeStart=` + parseInt(rangeStart) + `&rangeEnd=` + parseInt(rangeEnd)).then(res => {
					return res.text();
				}).then(data => {
					$('#myPostsPerCategory').empty();
					$('#myPostsPerCategory').html(data);

				})
			} else {
				const search_categories = $('#search-categories');
				search_categories.empty().addClass('search-categories-loader');
				if (back_to_top.length === 1) back_to_top[0].click();
				fetch(`/wp-content/themes/generatepress_child/newsposts.php?lang=` + $('html').attr('lang') + `&rangeStart=` + parseInt(rangeStart) + `&rangeEnd=` + parseInt(rangeEnd)).then(res => {
					return res.text();
				}).then(data => {

					search_categories.html(data).removeClass('search-categories-loader');
					attachClickEventToSearch();
					attachClickEventToRangeEnd();
					attachClickEventToRangeStart();

				})
			}
		});
	}

	var attachClickEventToRangeStart = function () {
		$("input[name='rangeStart']").on('click', function () {
			var allSelectedCategories = [];
			var categories = {
				"blogName": ""
			};
			var blogName = "";
			var rangeStart = $("input[name='rangeStart']").attr('range');
			var rangeEnd = $("input[name='rangeEnd']").attr('range');
			$("input[name='rangeStart']").attr('range', '' + (parseInt(rangeStart) - 15));
			$("input[name='rangeEnd']").attr('range', '' + (parseInt(rangeEnd) - 15));
			rangeStart = $("input[name='rangeStart']").attr('range');
			rangeEnd = $("input[name='rangeEnd']").attr('range');

			const categories_blog_name = $('#searchNav #categories_blog_name').val();

			const back_to_top = $('.generate-back-to-top');

			if (categories_blog_name !== '' && categories_blog_name.toLowerCase() !== 'all') {

				categories = {
					"blogName": categories_blog_name
				};
				allSelectedCategories.push(categories);

				var request = JSON.stringify(allSelectedCategories);

				fetch(`/wp-content/themes/generatepress_child/newsposts.php?domains=` + request + `&lang=` + $('html').attr('lang') + `&rangeStart=` + parseInt(rangeStart) + `&rangeEnd=` + parseInt(rangeEnd)).then(res => {
					return res.text();
				}).then(data => {
					$('#myPostsPerCategory').empty();
					$('#myPostsPerCategory').html(data);
				})
			} else {
				const search_categories = $('#search-categories');
				search_categories.empty().addClass('search-categories-loader');
				if (back_to_top.length === 1) back_to_top[0].click();
				fetch(`/wp-content/themes/generatepress_child/newsposts.php?lang=` + $('html').attr('lang') + `&rangeStart=` + parseInt(rangeStart) + `&rangeEnd=` + parseInt(rangeEnd)).then(res => {
					return res.text();
				}).then(data => {
					$('#search-categories').empty();
					$('#search-categories').html(data).removeClass('search-categories-loader');
					attachClickEventToSearch();
					attachClickEventToRangeEnd();
					attachClickEventToRangeStart();
				})
			}

		});
	}

	$(document).ready(function () {
		var rangeStart = '' + 0;
		var rangeEnd = '' + 15;


		fetch(`/wp-content/themes/generatepress_child/newsposts.php?lang=` + $('html').attr('lang') + `&rangeStart=` + parseInt(rangeStart) + `&rangeEnd=` + parseInt(rangeEnd)).then(res => {
			return res.text();
		}).then(data => {
			$('#search-categories').html(data).removeClass('search-categories-loader');
			attachClickEventToSearch();
			attachClickEventToRangeStart();
			attachClickEventToRangeEnd();

		})

	});


	$(document).ready(function () {

		if ($('body.theme--biznes').length > 0) {
			const _URL = '/wp-content/themes/generatepress_child/eventsPerDomain.php';

			const _currentMonth = moment().format("YYYY-MM");
			const _the_month = $('#TheMonth2');
			let avaiableDates;
			let is_next_month = true;
			let month_array = [];

			const getDates = () => {
				fetch(`${_URL}?getDates=true&lang=` + $('html').attr('lang')).then(res => res.json()).then(data => {
					avaiableDates = data;
					updateNav(_currentMonth)
				});
			}

			const updateNav = (currentMonth) => {
				const index = avaiableDates.indexOf(currentMonth);

				$('#previous2').attr({
					'disabled': (avaiableDates[0] === currentMonth),
					'data-month': avaiableDates[index - 1]
				})

				$('#next2').attr({
					'disabled': (avaiableDates[avaiableDates.length - 1] === currentMonth),
					'data-month': avaiableDates[index + 1]
				})
			}

			const getEvents = (month) => {

				const _emptyResultsContainer = $('.events-calendar__empty-results');
				const _events = $("#events2");
				_emptyResultsContainer.hide();
				_events.empty();
				_the_month.html(moment(month).format('MMMM') + ' ' + moment(month).year());

				fetch(`${_URL}?month=${month}` + `&lang=` + $('html').attr('lang'))
					.then(res => res.json())
					.then(data => {

						data_calendar_events.creatCheckbox(data);

						if (month != month_array[month_array.length - 1] && is_next_month == false) {

							getEvents(month_array[month_array.length - 1]);
						} else {

							is_next_month = true;
							month_array = [];

							if ((!data) || (data.length === 0)) {
								_emptyResultsContainer.fadeIn();
								return false;
							}

							for (var item of data) {

								let templateSingleEvent = `
								<div  class="events-list__item" domainname="${item.domaine}">
									<a class="events-list__link" href="${item.permalink}" >
										<span  class="events-list__date">${item.date_start}`;

								if (item.date_end !== "") templateSingleEvent += ` - ${item.date_end}`;

								templateSingleEvent += `</span>
										<span  class="events-list__description">${item.title} 
								`;

								if (item.excerpt !== "") templateSingleEvent += ` - ${item.excerpt}`;

								templateSingleEvent += `
										</span>
									</a>
								</div>
								`;
								_events.append(templateSingleEvent);
							}

						}
					})
			}

			getDates();
			getEvents(_currentMonth);

			$('.events-calendar__button').on('click', function () {

				const month = $(this).attr('data-month');
				month_array.push(month);
				updateNav(month_array[month_array.length - 1]);
				_the_month.html(moment(month_array[month_array.length - 1]).format('MMMM') + ' ' + moment(month_array[month_array.length - 1]).year());

				if (!is_next_month) return;
				is_next_month = false;

				setTimeout(() => {
					getEvents(month_array[month_array.length - 1]);
				}, 400);

			});

			//For subpage Calendar events
			let data_calendar_events = {};
			data_calendar_events.domains_name_array = [];
			data_calendar_events.section_subpage_events_calendar = $('.js-subpage-events-calendar .search-filter--categories');
			data_calendar_events.events = $('.js-subpage-events-calendar .event-list');
			data_calendar_events.creatCheckbox = function (data) {

				if (data_calendar_events.section_subpage_events_calendar.length < 1) return;

				data_calendar_events.domains_name_array = [];
				data_calendar_events.events.siblings('.no-results').addClass('hide');

				if (data) {
					for (let item of data) {
						if (item.domaine !== '' && !data_calendar_events.domains_name_array.includes(item.domaine)) data_calendar_events.domains_name_array.push(item.domaine);
					}
				}

				if (data_calendar_events.section_subpage_events_calendar.length > 0) {
					let item_checkbox = '';
					data_calendar_events.section_subpage_events_calendar.empty();

					for (var item of data_calendar_events.domains_name_array) {
						item_checkbox += `
						<input type="checkbox" id="${item}" blogName="${item}" name="${item}" checked>
						<label for="${item}"> <span>${item}</span></label><br>
						`
					}

					data_calendar_events.section_subpage_events_calendar
						.append(item_checkbox)
						.find('input[type="checkbox"]')
						.on('change', function () {
							const name = $(this)[0].name;

							const items = data_calendar_events.events.find('>.events-list__item');
							const isChecked = $(this)[0].checked;

							if (items.length > 0 && !isChecked) {
								const domain = data_calendar_events.events.find(`[domainname="${name}"]`);

								domain.addClass('hide');

							} else {

								data_calendar_events.events.find(`[domainname="${name}"]`).removeClass('hide');
							}

							const hide_elements = data_calendar_events.events.find('.hide');

							if (items.length == hide_elements.length) {

								data_calendar_events.events.siblings('.no-results').removeClass('hide');

							} else {
								data_calendar_events.events.siblings('.no-results').addClass('hide');
							}
						});
				}
			}
		}
	})
	//end if is biznes
	$(document).ready(function () {
		const i = $('.single-studies .entry-content-grid__column-right .acf-text');

		if (i.length > 0) {
			let str = i[0].innerHTML;
			let mail = [],
				www = [];
			str = str.replace(/(<([^>]+)>)/gi, " ");
			str = str.trim();
			str = str.replace(/ +/g, " ");
			const words = str.split(' ');

			mail = words.filter((item) => {
				const reg = /[a-zA-Z0-9.\-_]+@[a-zA-Z0-9\-.]+\.[a-zA-Z]{2,4}/i;

				return reg.test(item);
			});
			mail = mail.map(item => {
				return item.trim();
			});
			mail = mail.filter((item, pos) => {

				return mail.indexOf(item) == pos;
			});

			www = words.filter(item => {
				const reg = /(((https?:\/\/)|(www\.))[^\s]+)/g;
				return reg.test(item);
			})
			www = www.map(item => {
				return item.trim();
			});
			www = www.filter((item, pos) => {

				return www.indexOf(item) == pos;
			});


			str = i[0].innerHTML;
			str = str.replace(/<a[^>]+?>|<\/a>/gmi, "");
			mail.forEach(element => {
				str = str.replaceAll(element, `<a href="mailto:${element}">${element}</a>`);
			});

			www.forEach(element => {

				str = str.replaceAll(element, `<a href="http://${element}">${element}</a>`);
			});

			i.empty().html(str);
		}
	});

	/**
	 * Change accordion elements to indexed + fired up event click
	 */
	const colection_accordion_bauttons = $('.wp-block-ub-content-toggle .wp-block-ub-content-toggle-accordion-title-wrap');
	colection_accordion_bauttons
		.each(function () {
			$(this).attr('tabindex', '0');
		})
		.on('keyup', function (e) {
			e.stopPropagation();
			if (e.which == 13) $(this).click();
		});

	/**
	 * Add tabindex for selected elements
	 */
	$('.add_tabindex, form .sf-field-taxonomy-job_offers_category  label.sf-label-radio').each(function () {
		$(this).attr('tabindex', '0');
	});

	$(document).on('focusin', '.main-navigation span.dropdown-menu-toggle', function (e) {
		const _this = $(this);
		const text = _this.data("title");

		if (text) {
			if (_this.find('.sr-only').length == 0) $(`<span class='sr-only'>${text}</span>`).appendTo(_this);
		}

	});

	$(document).on('focusout', '.main-navigation span.dropdown-menu-toggle', function () {
		$(this).empty();
	})

})(jQuery);

(function($){

	const formSearchFilter = $('.searchandfilter select option');

	if(formSearchFilter.length >0) {
	
		[...formSearchFilter].forEach(option => {
			
			const value = option.getAttribute('value');
			const new_walue = value.replace('\u00a0', ' ');
		
			if(new_walue) {
				option.setAttribute('value',new_walue);
			} 
		})
	}
})(jQuery);